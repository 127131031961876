import { ErrorType } from "@evercam/api/types"

const createErrorClass = (errorName: string) => {
  return class extends Error {
    constructor(error: unknown) {
      const e =
        error instanceof Error
          ? error
          : new Error(typeof error === "string" ? error : "unknown")
      super(e.message)
      this.name = errorName
      this.message = e.message
      this.stack = (e.stack || "").replace(/^Error: /, `${errorName}: `)
    }
  }
}

export const BadRequestError = createErrorClass(ErrorType.BadRequestError)
export const NotFoundError = createErrorClass(ErrorType.NotFoundError)
export const UnauthorizedError = createErrorClass(ErrorType.UnauthorizedError)
export const GatewayTimeoutError = createErrorClass(
  ErrorType.GatewayTimeoutError
)
export const BadGatewayError = createErrorClass(ErrorType.BadGatewayError)
export const ForbiddenError = createErrorClass(ErrorType.ForbiddenError)
export const HlsError = createErrorClass(ErrorType.HlsError)
export const SnapshotError = createErrorClass(ErrorType.SnapshotError)
export const ConflictError = createErrorClass(ErrorType.ConflictError)
export const InternalServerError = createErrorClass(
  ErrorType.InternalServerError
)
